import M$plugin$OverviewMap from './facade/js/overviewmap';
import M$control$OverviewMapControl from './facade/js/overviewmapcontrol';
import M$impl$control$OverviewMapControl from './impl/ol/js/overviewmapcontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.OverviewMap = M$plugin$OverviewMap;
window.M.control.OverviewMapControl = M$control$OverviewMapControl;
window.M.impl.control.OverviewMapControl = M$impl$control$OverviewMapControl;
